const DataTable = $.fn.dataTable
$.extend(true, DataTable.Buttons.defaults, {
  dom: {
    button: {
      className: ''
    }
  }
})

$.extend(DataTable.defaults, {
  fnDrawCallback: function (oSettings) {
    $('.dataTables_length select').select2({
      minimumResultsForSearch: -1
    })
  }
})
$(document).ready(function () {
  $(".merge-selected").click(function(e){
    e.preventDefault()
    const peopleIds = $("#home-list").DataTable().rows({ selected: true }).ids();
    const toPersonId = $(this).data("personId")
    const $form = $("#edit-merge-form")
    $form.find("input[name='people_ids']").val(peopleIds.join(','))
    $form.find("input[name='to_person_id']").val(toPersonId)
    if (peopleIds.length > 0 && toPersonId) {
      $form.submit()
    }
  })

  function submitExportForm($form, dt) {
    const peopleIds = dt.rows({ selected: true }).ids()
    const rowsToExport = peopleIds.length ? dt.rows({ selected: true }) : dt.rows({ filtered: 'applied' })
    $('#export-modal').modal('show')
    window.setTimeout(() => $('#export-modal').modal('hide'), 1000)
    $form.find("input[name='export_people_ids']").val(rowsToExport.ids().join(','))
    const membershipIds = rowsToExport.nodes().toArray().map(e =>
      e.dataset.membershipIds.split(',')
    ).flat()
    $form.find("input[name='export_membership_ids']").val(membershipIds.join(','))
    if ($('#query_date').length) {
      $form.find("input[name='hidden_query_date']").val($('#query_date').val())
    }
    const columns = []
    $('#home-list').DataTable().columns().every(function () {
      if (this.visible()) {
        columns.push($(this.header()).attr('data-name'))
      }
    })
    $form.find("input[name='columns']").val(columns.join(','))
    $form.submit()
  }

  var dt = $('#home-list').DataTable({
    dom: '<"#home-header"Bf>tip',
    paging: false,
    stateSave: true,
    autoWidth: false,
    // responsive: true,
    orderClasses: false,
    processing: true,
    columnDefs: [
      {
        targets: 0,
        checkboxes: {
          selectRow: true,
          stateSave: false
        }
      },
      {
        targets: 'not_searchable',
        searchable: false
      }
    ],
    select: {
      style: 'multi',
      blurable: true
    },
    order: [[4, 'asc']],
    buttons: {
      dom: {
        container: {
          tag: 'div',
          className: 'btn-group'
        }
      },
      buttons: [
        {
          extend: 'colvis',
          text: '<span class="fas fa-filter"></span> Show Columns',
          className: 'btn btn-sm btn-outline-dark',
          titleAttr: 'Select Columns'
        },

        {
          text: '<span class="fas fa-save"></span> Create Snapshot',
          className: 'btn btn-sm btn-outline-dark',
          action: function (e, dt, node, config) {
            $('#snapshot-modal').modal('show')
            const peopleIds = dt.rows({ selected: true }).ids()
            if (peopleIds.length > 0) {
              $('#selected_people_ids_csv').val(peopleIds.join(','))
            } else {
              $('#selected_people_ids_csv').val(dt.rows({ filtered: 'applied' }).ids().join(','))
            }
            $('#snapshots-form').submit()
          },
          available: function (_dt, _config) {
            return gon.can_snapshot
          }
        },

        {
          extend: 'print',
          className: 'btn btn-sm btn-outline-dark',
          text: '<span class="fas fa-print"></span>',
          titleAttr: 'Print',
          exportOptions: {
            columns: ':visible :not(.sorting_disabled)'
          }
        },

        {
          extend: 'collection',
          className: 'btn btn-sm btn-outline-dark',
          text: '<span class="fas fa-share"></span> Export',
          buttons: [
            {
              text: '<span class="fas fa-file-excel fa-fw"></span> Excel',
              action: function (e, dt, node, config) {
                submitExportForm($("#export-form-excel"), dt)
              }
            },
            {
              text: '<span class="fas fa-calendar-check fa-fw"></span> Monthly Report',
              action: function (e, dt, button, config) {
                $('#export-modal').modal('show')
                $('#hidden_query_date_monthly').val($('#query_date').val())
                $('#export-form-monthly').submit()
                $('#export-modal').modal('hide')
              }
            },
            {
              text: '<span class="fas fa-file-csv fa-fw"></span> CSV',
              action: function (e, dt, node, config) {
                submitExportForm($('#export-form-csv'), dt)
              }
            }
          ]
        }
      ]
    }
  })
})
